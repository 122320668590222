.container-modal-total {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  display: flex;
  padding: 10px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
}
.box-mobile {
  display: flex;
  padding: 10px;
  height: 300px;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
}
.result-cropper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  /* height: 100%; */
}
.container-modal-crop {
  width: 70%;
  height: 400px;
}
@media (max-width: 600px) {
  .container-modal-crop {
    width: 100%;
    /* height: 400px; */
  }
}
.img-preview {
  overflow: hidden;
}
.input {
  display: none;
}
.image-cropped {
  width: 100%;
  height: 100%;
}
.custom-file-upload {
  border: 1px solid #014478;
  display: inline-block;
  padding: 6px 17px;
  cursor: pointer;
  margin-top: 30px;
  color: white;
  background-color: #014478;
  border-radius: 10px;
  height: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonDef {
  margin-left: 20px;
  margin-top: 30px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  background-color: orange;
  padding: 4px 10px;
  color: white;
  font-size: 12px;
  border: 1px solid orange;
}
.buttonDefMobile {
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  background-color: orange;
  padding: 4px 10px;
  color: white;
  font-size: 15px;
  border: 1px solid orange;
}

.btnCut {
  padding: 4px 10px;
  height: 40px;
  width: 150px;
  font-size: 12px;
  background-color: #014478;
  border-radius: 10px;
  color: white;
  border: 1px solid #014478;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.btnCut-mobile {
  padding: 4px 10px;
  height: 40px;
  width: 150px;
  font-size: 12px;
  background-color: #014478;
  border-radius: 10px;
  color: white;
  border: 1px solid #014478;
  margin-bottom: 10px;
  margin-top: 20px;
}

.buttonClose {
  /* position: absolute; */
  /* right: 0; */
  /* margin-right: 70px; */
  margin-left: 30px;
  margin-top: 30px;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  color: white;
  background-color: red;
  font-size: larger;
  font-weight: bold;
  border: 1px solid red;
}

@media (max-width: 600px) {
  .buttonClose {
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-left: 30px;
    margin-top: 30px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    color: white;
    background-color: red;
    font-size: larger;
    font-weight: bold;
    border: 1px solid red;
  }
}
.container-btns {
  display: flex;
  margin-top: 40px;
}
.container-btns-mobile {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.cropper-box-container {
  height: 300px;
  width: 100%;
  display: flex;
}
