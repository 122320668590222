.btnWhatsapp {
  z-index: 9999999999999999;
  border-radius: 100px;
  background-image: url("../../assets/img/brand/logoWp.png");
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: 167%;
  border: 2px solid #32d951;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  right: 20px;
  bottom: 10%;
}

@media screen and (max-width: 480px) {
  .btnWhatsapp {
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 10%;
  }
}
