.react-bs-container-body tr {
  border-top-style: hidden !important;
  border-top: 0px;
}

.react-bs-container-header tr {
  border-bottom-style: hidden !important;
  border-top: 0px;
}

.react-bs-container-body th {
  border-top-style: hidden !important;
  border-top: 0px;
}

.react-bs-container-header th {
  border-top-style: hidden !important;
  border-top: 0px;
}

.react-bs-container-body td {
  border-top-style: hidden !important;
  border-top: 0px;
}

.react-bs-container-header td {
  border-bottom-style: hidden !important;
  border-top: 0px;
}
