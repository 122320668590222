[data-loader="circle-side-summary"] {
  width: 50px;
  height: 50px;
  -webkit-animation: circle infinite 0.95s linear;
  -moz-animation: circle infinite 0.95s linear;
  -o-animation: circle infinite 0.95s linear;
  animation: circle infinite 0.95s linear;
  border: 2px solid #16b0fe;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
}

.modalImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageModal {
  object-fit: contain;
  max-height: 80%;
}

.container-modal-add-explanation-exam {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}
.subtitle-modal-add-explanation-exam {
  font-weight: bold;
  margin-bottom: 10px;
}
.textarea-modal-add-explanation-exam {
  min-height: 100px;
  padding: 10px;
}

.button-save-modal-add-explanation-exam {
  border: none;
  background-color: #e07c2c;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  color: #fff;
  margin-bottom: 10px;
}
