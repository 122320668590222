/* over put all alert over all content */
/* @import url('~draft-js/dist/Draft.css'); */

.animated {
  z-index: 1500;
}
.error {
  width: 100%;
  color: red;
  font-weight: 500;
  padding-top: 5px;
}

.image-profile-form {
  height: 150px;
  width: 150px;
  border-radius: 8px;
  padding: 5px;
}
.react-tagsinput-input {
  width: auto !important;
  padding: 2px !important;
}
.react-tagsinput-tag {
  background-color: #2873b8 !important;
  border: 1px solid #2873b8 !important;
  color: #fff !important;
}

.react-tagsinput--focused {
  border-color: #2873b8 !important;
}
.bg-instagram {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}

.btn-80 {
  width: 80%;
}
.DateInput__small {
  width: 40% !important;
}

.font-file-name {
  font-weight: 300;
}
.img-notifications {
  width: 200px;
  height: 200px;
}
/* .card {
  border: none !important;
} */
