[data-loader="circle-side-summary"] {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  margin-top: 4px;
  -webkit-animation: circle infinite .95s linear;
  -moz-animation: circle infinite .95s linear;
  -o-animation: circle infinite .95s linear;
  animation: circle infinite .95s linear;
  border: 2px solid #16B0FE;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
}