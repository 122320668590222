/* HOME TEMPORAL */
@media (max-width: 767px) {
    .webcam {
        width: 100px;
        margin: auto;
    }
    .camaras {
        width: 25%;
    }
    .txtprecios {
        width: 75%;
    }
    .portadaterms {
        padding: 1em;
    }
}

/* FIN DE HOME TEMPORAL */

nav {
    margin-top: -10px;
}

.popover{
   /* min-width: 900px;*/
    max-width: 1000px;
    
}

.list-group{
    max-height: 278px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.juguetes {
    background: #a2c6e0ba url(../img/hero_home_bg_1.svg) no-repeat center bottom;
    height: 100%;
    width: 100%;
    position: absolute;
}

#custom-search-input-mark {
    margin: 0;
    top: 0;
    padding: 0;
    width: 210px;
    margin: auto;
    position: relative;
}

.search-query-mark {
    width: 100%;
    height: 35px;
    padding-left: 20px;
    padding-top: 3px;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    font-size: 0.9rem;
    color: #333;
    background-color: #f9f9f9;
}

/* ARCHIVO */
.citamascercana {
    font-size: 12px;
}

.fechayhora {
    font-weight: 600;
    color: #0069bc !important;
    font-size: 1.1em;
    margin-left: 5px;
}

.fechayhora:hover {
    color: #16B0FE !important;
}

.strip_list ul li:last-child a {
    font-size: 0.9em;
}

.cajonesabajo {
    display: flex;
    flex-direction: row;
    margin: 4px -16px 0 -115px;
}

.cajonesabajo a {
    color: #fff;
}

.cajonconsul {
    background-color: #0064B8;
    width: 33.33%;
    margin: 0;
    padding: 20px 20px 0px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cajoncasa {
    background-color: #0064b8d9;
    width: 33.33%;
    margin: 0;
    padding: 20px 20px 0px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cajonvideo {
    background-color: #0064b8b5;
    width: 33.33%;
    margin: 0;
    padding: 20px 20px 0px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cajonesabajo img {
    max-width: 40px;
}

.cajonesabajo h3 {
    color: #fff;
    font-size: 1em;
    margin-top: 10px;
    margin-left: 7px;
}

.cajonesabajo ul {
    color: #fff;
    margin-top: -10px;
}

.infodate {
    margin-right: 8px;
}

.infodate i {
    margin-right: 3px;
    color: #ffffff7a;
}

.conteresults {
    padding: 0 15px;
}

.strip_list-2 ul li:last-child a {
    font-size: 0.9em;
}

.strip_list-2 {
    margin-bottom: 25px;
    background-color: #fff;
    padding: 20px 15px 15px 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.strip_list-2 a.wish_bt {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #555;
}

.strip_list-2 a.wish_bt:hover:after {
    color: #16B0FE;
}

@media (max-width: 767px) {
    .strip_list-2 {
        /* For less than 767px */
        padding: 20px 15px 15px 90px;
    }
}

.strip_list-2 figure {
    width: 80px;
    height: 80px;
    position: absolute;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    left: 15px;
    top: 15px;
}

.strip_list-2 figure img {
    width: auto;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

@media (max-width: 767px) {
    .strip_list-2 figure img {
        /* For less than 767px */
        height: 60px;
    }
}

@media (max-width: 767px) {
    .strip_list-2 figure {
        /* For less than 767px */
        width: 60px;
        height: 60px;
    }
}

.strip_list-2 h3 {
    font-size: 1.2rem;
    margin-top: 0;
    display: inline-block;
    margin-right: 10px;
}

.strip_list-2 p {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .strip_list-2 p {
        /* For less than 767px */
        font-size: 13px;
        font-size: 0.8125rem;
    }
    .img-fluid{
        max-width: 138px !important;
    }
    .strip_list-2 small {
        font-size: larger  !important;
    }
}

.strip_list-2 small {
    color: #999;
    font-size: smaller;
    display: inline;
    font-weight: 800;
    text-transform: uppercase;
}

.strip_list-2 ul {
    border-top: none;
    margin: -12px 0px 0px -15px;
    padding: 20px 0px 20px 15px;
}


.strip_list-2 ul li {
    /* display: inline-block; */
    margin-right: 5px;
    font-weight: 500;
}

.strip_list-2 ul li a {
    color: #999;
    cursor: pointer;
}

.strip_list-2 ul li a:hover {
    color: #16B0FE;
}

/*
.strip_list-2 ul li:first-child:after {
  content: '|';
  font-weight: 400;
  margin-left: 8px;
  color: #ccc;
}
*/
.strip_list-2 ul li:last-child {
    margin-right: 0;
}

.strip_list-2 ul li:last-child a {
    border: 2px solid #16B0FE;
    color: #16B0FE;
    cursor: pointer;
    padding: 5px 20px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
}

.strip_list-2 ul li:last-child a:hover {
    background-color: #16B0FE;
    color: white !important;
}



.colsmark {
    padding: 0px 12px 0px 12px;
}

td.padding-celda {
    padding: 10px;
}

.linea-divisora {
    height: 1px;
    width: 100%;
    background-color: #f2f2f2;
    margin: 10px 10px 10px 0px;
}

.colsmark img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.colsmark h3 {
    margin-bottom: 0;
}

.colsmarkresultstitle {
    text-align: center;
    background-color: #4a91cc;
}

.colsmarkresultstitle h3 {
    font-size: 0.9em;
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 20px;
    color: #fff;
}

.colsmarkresultstitle img {
    border-radius: 0px;
    width: 50px;
    height: auto;
    margin-top: 20px;
}

.colsmarkresults {
    text-align: left;
    background-color: #fff;
    color: #000;
}

.colsmarkresults h3 {
    font-size: 0.9em;
    margin-top: 20px;
    color: #4a91cc;
}

.colsmarkresults .infodate i {
    margin-right: 3px;
    margin-left: 3px;
    color: #4a91cc;
}

.colsmarkresults .infodate {
    margin-right: 0px;
}

.col1 {
    width: 40%;
}

.col2 {
    width: 20%;
    margin-left: -35px;
    margin-right: 5px;
}

.col3 {
    width: 20%;
    margin-left: 5px;
    margin-right: 5px;
}

.col4 {
    width: 20%;
    margin-left: 5px;
    margin-right: 5px;
}

.masopciones {
    font-size: 0.9em;
}

.col1ex {
    width: 70%;
}

.col2ex {
    width: 30%;
    margin-left: 5px;
    margin-right: 5px;
}
img.imagen-logos-labs {
    height: 90px;
    width: auto;
    border-radius: 0;
    display: block;
    margin-bottom: -7px;
}
.medicoenexamenes .masopciones {
    margin-left: 0px
}

.observacionesmark {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
}
.colsmarkresults .examsylabs {
    text-align: right;
    background-color: #fff;
    color: #000;
}
.resultadosexams {
    margin-top: 15px;
    text-align: left;
    background-color: #0c628e;
    padding: 20px 5px;
    color: #fff;
    border-radius: 5px;
}
.row.resultadosexams i {
    color: #ffffff9c;
    font-size: 20px;
    line-height: 1.7;
}
.citadisponible {
    text-align: center;
    background-color: #08354c;
    padding: 8px;
    color: #fff;
    margin-bottom: -15px;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 300;
    border-radius: 5px 5px 0px 0px;
}
.col-md-4 {
    padding-right: 10px;
    padding-left: 10px;
}

.cajondetitulos {
    border-top: 1px solid #eaeaea;
    padding-top: 10px;
    margin-bottom: -10px;
    margin-top: 5px;
}
.cajonmedicos {
    text-align: center;
}
.cajonmedicos h3 {
    font-size: 12px;
    color: #4a91cc;
    /* margin-top: 10px; */
    margin-bottom: -10px;
}
.cajonmedicos ul {
    text-align: left;
    margin-left: -2px;
}
.cajonmedicos .infodate i {
    margin-right: 3px;
    color: #4a91cc;
}
.col-xs-4 {
    width: 33.33%;
}
.col-xs-4 {
    width: 33.33%;
    padding-left: 0;
    padding-right: 0;
}

/* RESPONSIVE */

@media only screen and (max-width: 600px) {
  .strip_list-2 {
    padding: 20px 15px 15px 20px;
}
    .colsmarkresultstitle img {
    width: 30px;
}
    .conteresults {
    display: none;
}
}

